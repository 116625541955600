// LicenseTerms.js
import React from 'react';
import {
  Modal,
  Button
} from 'react-bootstrap';

function LicenseTerms({
  show,
  handleClose
}) {
  return (
    <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>papeye.com License Terms</Modal.Title>
            </Modal.Header>
            <Modal.Body>

      <style jsx>{`
        .small-text {
          font-size: 0.8em;
        }
      `}</style>

<p className="small-text">This document outlines the terms specific to the use of the <a href="https://www.papeye.com">papeye.com</a> Cloud Analytics Platform (“Platform”).</p>
<h5>Applicability</h5>
<p className="small-text">Unless otherwise agreed, the following terms apply to the usage of the <a href="https://www.papeye.com">papeye.com</a> Cloud Analytics Platform (“Platform”).</p>
<h5>Reference Documents</h5>
<p className="small-text">This license agreement is subject to the Finnish IT2022 YSE – General Terms and Conditions and IT2022 ETP – Special Terms and Conditions for Services Delivered Over Data Network (Cloud Service).</p>
    <p className="small-text">
    <ul>
    <li><a href="https://www.tapiotechnologies.com/wp-content/uploads/2023/09/PapEye_General_Terms_YSE_IT2022_English.pdf">IT2022 YSE – GENERAL TERMS AND CONDITIONS</a></li>
    <li><a href="https://www.tapiotechnologies.com/wp-content/uploads/2023/09/PapEye_Cloud_Terms_ETP_IT2022_English.pdf">IT2022 ETP – SPECIAL TERMS AND CONDITIONS FOR SERVICES DELIVERED OVER DATA NETWORK (CLOUD SERVICE)</a></li>
    </ul>
    </p>
<h5>Scope of License</h5>
<p className="small-text">The license to use the software granted under this agreement is restricted to a single mill or organizational unit per user account. Multiple mills or organizational units require separate accounts and corresponding licenses. Contact info@tapiotechnologies.com for licenses.</p>
<h5>License Price and Billing</h5>
<p className="small-text">Prepayment Required: The license for the cloud service is billed annually, and payment for the year must be made in advance, unless otherwise agreed.</p>
<h5>Cancellations</h5>
<p className="small-text">Term-Based Cancellation: Customers may cancel the service at any time, but the cancellation will only apply for the subsequent billing term. The prepaid amount for the current term is non-refundable.</p>
<h5>Identifiers and Security</h5>
<p className="small-text">The supplier will provide the necessary identifiers for accessing the cloud service. Customers are responsible for maintaining the confidentiality of these identifiers.</p>
<h5>Data Ownership and Intellectual Property</h5>
    <p className="small-text">
    <ul>
    <li>Customer Property: All customer data and material remain the property of the customer.</li>
    <li>Data Usage for Product Development: The supplier reserves the right to use customer-uploaded data in an aggregated and anonymized form for internal product development purposes.</li>
    <li>Data Storage Duration: Customer data is guaranteed to be stored for one year.</li>
    </ul>
    </p>
<h5>Service Availability and Maintenance</h5>
<p className="small-text">The supplier reserves the right to conduct maintenance activities which may result in short service interruptions. Advance notice will be provided where possible.</p>
<h5>Backups</h5>
<p className="small-text">The supplier is responsible for regular backups of customer material stored on the cloud service.</p>
<h5>Termination Assistance</h5>
<p className="small-text">Upon the expiration or termination of the agreement, no termination assistance will be provided by the supplier. The customer is responsible for downloading all their material from the service within the terms of the agreement.</p>

      </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
  );
}

export default LicenseTerms;
